body {
    font-family: "Lato",Calibri,Arial,sans-serif;
    color: gray;
    padding: 0;
    width: 100%;
    background-color: #202020;
    font-size: 16px;
    text-align: justify;
    max-width: 700px;
    margin-top: 26px;
    margin-left: auto;
    margin-right: auto;
}

.navigation-bar {
    text-align: center;
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
}

.navigation-bar li {
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
}

.navigation-bar a {
    text-transform: uppercase;
    color: rgba(111,111,111,0.8);
    font-weight: 350;
    text-decoration: none;
}

.navigation-bar a.current  {
    color: rgba(187,187,187,0.8);
}

.navigation-bar a:hover {
    color: rgba(187,187,187,0.8);
}

h1 {
    font-weight: 350;
    font-kerning: normal;
}

h1.site-title {
    text-align: center;
}

hr {
    border: 1px solid rgba(128,128,128,0.5);
}

ul.server-list {
    padding: 0px;
}

ul > li.post {
    background-color: #403f3f;
    padding: 15px;
    list-style-type: none;
    margin-bottom: 10px;
}

.post a {
    color: white;
    font-weight: 350;
    text-decoration: none;
    text-transform: uppercase;
}

.post img {
    margin-right: 20px;
}

a.donate {
	box-shadow:inset 0px 1px 0px 0px #54a3f7;
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	background-color:#007dc1;
	border-radius:3px;
	border:1px solid #124d77;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:13px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #154682;
}
a.donate:hover {
	background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
	background-color:#0061a7;
}
a.donate:active {
	top:1px;
}

a {
	color: gray;
}

div.donate {
    text-align: end;
    float: right;
    margin-top: 10px;
}

ul.sidebar li {
    text-align: center !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    display: inline-block;
}

ul.sidebar {
    padding: 0;
}

ul.sidebar li:before {
    content: '\ffed';
    margin-right: 25px;
}

ul.sidebar li:first-of-type:before {
    content: '';
    margin-right: 10px;
}

.sidebar a {
    text-transform: uppercase;
    color: rgba(111,111,111,0.8);
    font-weight: 350;
    text-decoration: none;
}

.sidebar h3 {
    text-align: center;
    margin-bottom: 5px;
}

body table.serverblock {
    background-color: #403f3f;
    color: #9c9c9c;
    padding: 15px;
    td:first-child {
        font-weight: bold;
        padding-right: 10px;
    }
}
